export const setCategories = (state, categories) => {
    state.categories = categories;
    state.isLoading = false
}

export const setSelectedCategory = (state, category) => {
    state.selectedCategory = category;
}

/**
 * Products
 */
export const setServices = (state, services) => {
    state.services = services;
}

export const setIsLoading = (state, isLoading) => {
    state.isLoading = isLoading;
}