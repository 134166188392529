<template>
  <h4 :class="size">{{ text }}</h4>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  color: var(--color-text-primary);
  font-size: 2.5em;
  font-weight: 800;
  text-align: start;
  padding-top: 1em;

  &::after {
    content: ".";
    color: var(--color-accent);
    font-size: 1em;
  }
}

.md {
  font-size: 2em;
}

@media screen and (max-width: 768px) {
  h4 {
    text-align: center;
    font-size: 1.8em;
  }
}
</style>