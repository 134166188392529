import { createStore } from "vuex";

import serviceModule from '../modules/services/store';
import authModule from '../modules/auth/store';
import cartModule from '../modules/cart/store';
import accountModule from '../modules/account/store';

const store = createStore({
    modules: {
        service: serviceModule,
        auth: authModule,
        cart: cartModule,
        account: accountModule,
    }
})

export default store