import axios from "axios";

const AUTH_TOKEN = "CIwNZdsMslR4XbisJF33e34I8yWsWX9wX4WFozOrf";

const paymentApi = axios.create({
  baseURL: "https://beho.com.co/beho-api/payment",
  headers: {
    "Content-Type": "application/json",
    "Authorization": AUTH_TOKEN
  }
});

// Catch errors
paymentApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 0) {
      console.log("Sin conexión a internet");
    }
    return Promise.reject(error);
  }
);

export default paymentApi;