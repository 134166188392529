export const setUser = (state, user) => {
    state.user = user;
    // Save user to local storage
    localStorage.setItem("user", JSON.stringify(user));
}

export const setLoading = (state, isLoading) => {
    state.isLoading = isLoading;
}

export const setError = (state, error) => {
    state.error = error;
}

export const setSuccess = (state, success) => {
    state.error = success;
}