import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/styles.scss'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(App)
.use(router)
.use(store)
.use(Toast)
.use(VCalendar)
.mount('#app')
