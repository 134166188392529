<template>
  <button :class="color">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 1.2em;
  background-color: var(--color-accent);
  border: none;
  border-radius: 100px;
  padding: 10px 30px;
  font-weight: 700;
  // width: 100%;
}

.default {
  background-color: var(--color-accent);
}

.lila {
    background-color: var(--color-lila);
}

.dark {
    color: white;
    background-color: var(--color-text-primary);
}

.gray {
    color: var(--color-text-primary);
    background-color: var(--color-gray);
}

.outline {
    color: var(--color-text-primary);
    background-color: transparent;
    border: 2px solid var(--color-text-primary);
}

@media screen and (max-width: 768px) {
    button {
      margin: 0 auto;
    }
}

@media screen and (max-width: 1200px) {
    button {
  font-size: 1em;
    }
}
</style>>