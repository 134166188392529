<template>
  <HeaderComponent />
  <body class="content">
    <div class="container">
      <router-view />
      <Loading v-if="isLoading" />
      <FabWhatsapp/>
    </div>
  </body>
  <FooterComponent />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import FabWhatsapp from "@/components/FabWhatsapp.vue";

import Loading from "@/components/Loading.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: { FooterComponent, HeaderComponent, Loading, FabWhatsapp },

  methods: {
    ...mapActions("service", ["loadCategories", "loadServices"]),
    ...mapActions("auth", ["loadUser"]),
    ...mapMutations("cart", ["saveCart", "loadCart", "loadBillingDetails"]),
  },

  computed: {
    ...mapState("service", ["selectedCategory", "isLoading"]),
    ...mapState("cart", ["services"]),
  },

  created() {
    this.loadCategories();
  },

  watch: {
    selectedCategory(category) {
      this.loadServices(category);
    },
    services(services) {
      this.saveCart();
    },
  },

  mounted() {
    this.loadUser();
    this.loadCart();
    this.loadBillingDetails();
    document.title = "page title"; 
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: var(--color-bg-primary);
  padding-top: 100px;
}
</style>