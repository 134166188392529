export default {

    name: 'checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/modules/cart/layouts/CheckoutPage.vue'),

    children: [
        {
            name: 'cart',
            path: '/cart',
            component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/CartPage.vue'),
        },
        {
            path: '',
            name: 'select-date',
            component: () => import(/* webpackChunkName: "checkout" */ '@/modules/cart/views/SelectDate.vue'),
        },
        {
            path: 'detalle-facturacion',
            name: 'billing-details',
            component: () => import(/* webpackChunkName: "checkout" */ '@/modules/cart/views/BillingDetails.vue'),
        },
        {
            path: 'payment',
            name: 'payment',
            component: () => import(/* webpackChunkName: "paymentSelection" */ '@/modules/cart/views/PaymentSelectionPage.vue'),
        },
        {
            path: 'empty',
            name: 'empty-cart',
            component: () => import(/* webpackChunkName: "emptyCart" */ '@/modules/cart/views/EmptyCart.vue'),
        }
    ]
}