export const addService = (state, service) => {
  // Define Cantidad to 1 if not defined
  if (!service.Cantidad) {
    service.Cantidad = 1;
  }
  state.services = [...state.services, service];
};

export const addItem = (state, service) => {
  // Increase Cantidad of service
  state.services = state.services.map((item) => {
    if (item.Id === service.Id) {
      item.Cantidad++;
    }
    return item;
  });
};

export const removeItem = (state, service) => {
    const serviceToUpdate = state.services.find(item => item.Id === service.Id);
    serviceToUpdate.Cantidad--;
    // Remove service if Cantidad is 0
    if (serviceToUpdate.Cantidad === 0) {
        state.services = state.services.filter(item => item.Id !== service.Id);
    }
};

export const removeService = (state, service) => {
  state.services = state.services.filter(item => item.Id !== service.Id);
}

/**
 * Save cart on localStorage
 */
export const saveCart = (state) => {
  try {
    localStorage.setItem("cart", JSON.stringify(state.services));
  } catch (e) {
    console.error("Error saving cart", e);
  }
};

export const loadCart = (state) => {
  const payload = JSON.parse(localStorage.getItem("cart"));
  if (payload) {
    state.services = payload;
  }
};

export const clearCart = (state) => {
  state.services = [];
  localStorage.removeItem("cart");
};


/**
 * Checkout
 */
export const SET_AND_DELIVERY_PRICE = (state, data) => {
  state.homeDeliveryPrice = data.delivery;
  state.tax = parseFloat(data.tax);
}

export const SET_COUPON_CODE = (state, data) => {
  state.coupon = data;
}

export const SET_BILLING_DETAILS = (state, data) => {
  state.billingDetails = data;
  localStorage.setItem("billingDetails", JSON.stringify(data));
}

export const loadBillingDetails = (state) => {
  const data = JSON.parse(localStorage.getItem("billingDetails"));
  if (data) {
    state.billingDetails = data;
  }
}

export const clearBillingDetails = (state) => {
  state.billingDetails = null;
  localStorage.removeItem("billingDetails");
}

export const SET_SERVICE_DATE = (state, data) => {
  state.serviceDate = data;
}