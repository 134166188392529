export default {
    name: 'account',
    component: () => import(/* webpackChunkName: "services" */ '@/modules/account/layouts/AccountTemplate.vue'), 
    children: [
        {
            name: 'upcoming-appointments',
            path: "appointments/upcoming",
            component: () => import(/* webpackChunkName: "UpcomingAppointments" */ '@/modules/account/views/UpcomingAppointments.vue'),
        },
        {
            name: 'past-appointments',
            path: "appointments/past",
            component: () => import(/* webpackChunkName: "UpcomingAppointments" */ '@/modules/account/views/UpcomingAppointments.vue'),
        },
        {
            name: 'edit-profile',
            path: "profile/edit",
            component: () => import(/* webpackChunkName: "EditProfile" */ '@/modules/account/views/EditProfile.vue'),
        },
        {
            path: "",
            redirect: { name: 'upcoming-appointments' },
          },
    ]
}