import frontApi from "@/api/frontApi";
import paymentApi from "@/api/paymentApi";
import { toRaw } from "vue";

export const addService = async ({ commit }, product) => {
    commit('ADD_SERVICE', product);
}

export const getTaxAndDeliveryPrice = async ({ commit }) => {
    const {data} = await frontApi.get("/getTaxAndDeliveryPrice");
    commit('SET_AND_DELIVERY_PRICE', data.data);
}

export const sendOrder = async ({commit, rootGetters}, order) => {
    const userId = rootGetters["auth/userId"];

    // Load services data from getters
    const services = rootGetters["cart/getServicesInCart"];
    const couponId = rootGetters["cart/getCouponId"];

    // Iterate services
    const servicesList = [];
    for (let service of Object.values(services)) {
        servicesList.push({
            id: service.Id,
            cantidad: service.Cantidad,
            subTotal: service.Cantidad * service.Precio,
            servicio: service.Servicio,
        });
    }

    const payload = {
        idUsuario: userId,
        ...order,
        servicios: servicesList,
        couponId: couponId
    }

    // console.log(payload);
    const {data} = await frontApi.post("/createSolicitud", payload);

    if (payload.descuento > 0 && couponId > 0 && payload.metodoPago === "Efectivo") {
        const response = await frontApi.get(`/canjeaCupon/${couponId}/${userId}`);
        // console.log(response);
        commit('SET_COUPON_CODE', null);
    }

    commit('SET_SERVICE_DATE', null);

    return { type: data.response, message: data.mensaje };
}

export const checkCouponCode = async ({commit, rootGetters}, couponCode) => {
    const userId = rootGetters["auth/userId"];
    const {data} = await frontApi.get("/checkCouponCode/" + couponCode + "/" + userId);
    commit('SET_COUPON_CODE', data.data);

    return { type: data.response, message: data.mensaje, data: data.data };
}

export const getPaymentData = async ({}, payload) => {
    // Set payload to form data
    const formData = new FormData();
    for (let key in payload) {
        formData.append(key, payload[key]);
    }
    const {data} = await paymentApi.post("/getPaymentData/", formData );
    return data;
}