<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
          <TextPoint text="BEHO" />
          <ul class="footer__items">
            <li>
              <a href="https://beho.com.co/quienes-somos/">¿Qué es Beho?</a>
            </li>
            <li>
              <a href="https://beho.com.co/afiliate-a-beho/">Afíliate a Beho</a>
            </li>
            <li><a href="https://beho.com.co/contacto/">Contáctanos</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <TextPoint text="CONTACTO" />
          <ul class="footer__items">
            <li>Lunes a Sábado de 7:00 am <br />A 6:00 pm</li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=573123231841"
                target="_blank"
                ><span class="number">+57 (312) 323 1841</span></a
              >
            </li>
          </ul>
          <div class="social-buttons">
            <a
              href="https://www.facebook.com/behocol"
              class="icon"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/behocol/"
              class="icon"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=573123231841"
              class="icon"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <TextPoint text="AFÍLIATE" />
          <ul class="footer__items">
            <li>
              <a href="mailto:hablemos@beho.com.co">hablemos@beho.com.co</a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=573123231841"
                target="_blank"
                ><span class="number">+57 (312) 323 1841</span></a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-12 block">
          <TextPoint text="PAGOS" />
          <img
            class="payment-methods"
            :src="`${publicPath}images/payu-beho.png`"
            alt=""
          />
        </div>
      </div>
      <div class="copy">
        <a href="https://beho.com.co/tratamiento-de-datos/"
          >Términos & Condiciones</a
        >
        <a href="https://beho.com.co/tratamiento-de-datos/"
          >Tratamiento de datos</a
        >
      </div>
      <p class="copyright">©2022 Beho - Todos los derechos reservados.</p>
    </div>
  </footer>
</template>

<script>
import TextPoint from "@/components/TextPoint.vue";
export default {
  components: {
    TextPoint,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 73%;
}

footer {
  /* background: transparent
    linear-gradient(
      180deg,
      #fff8e7 0%,
      #eedaea 100%,
      #fe9478 100%,
      #ff8d6d 100%
    )
    0% 0% no-repeat padding-box; */

  background-image: linear-gradient(
    180deg,
    #fff8e7 0%,
    #eedaea 73%,
    #eedaea 74%
  ) !important;
  padding: 100px 0;

  h4 {
    font-size: 1.3em;
    font-weight: 900;
  }

  .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;

    a {
      display: block;
      color: var(--color-text-primary);
      text-decoration: none;
      font-weight: bold;
      font-size: 1.1em;
      margin: 20px;
    }
  }

  .copyright {
    margin-top: 40px;
    font-size: 0.9em;
    color: var(--color-text-primary);
    font-weight: 500;
  }

  ul.footer__items {
    padding: 0;
    margin-top: 20px;

    li {
      list-style: none;
      text-align: left;
      font-weight: 500;
      line-height: 1.7;
      margin: 10px 0;
      font-size: 0.9em;

      a {
        color: var(--color-text-primary);
        text-decoration: none;
      }

      span.number {
        color: var(--color-text-primary);
        font-weight: bold;
        font-size: 0.9em;
      }
    }
  }

  .block {
    text-align: start;
  }

  .payment-methods {
    width: 70%;
    aspect-ratio: auto 500 / 315;
  }

  .social-buttons {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    .icon {
      margin: 0 5px;
      color: transparent;

      i {
        font-size: 20px;
        color: white;
        width: 40px;
        height: 40px;
        background-color: #323232;
        border-radius: 50px;
        line-height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    h4 {
      text-align: center;
      margin-top: 40px;
    }
    ul.footer__items li {
      text-align: center;
    }
    .block {
      text-align: center;
    }

    .social-buttons {
      display: flex;
      justify-content: center;
    }

    .copy {
      display: block;
    }
  }
}
</style>
