/**
 * Traer información del state para procesarla
 */

export const getUser = (state) => {
    return state.user ? {...state.user} : null;
}

export const userId = (state) => {
    return state.user.Id
}

export const isAuthenticated = (state) => {
    return (state.user) ? true : false
}