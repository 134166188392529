import store from '../store'
export const isAuthenticatedGuard = async (to, from, next) => {
    // console.log(store.getters['auth/isAuthenticated']);
    return new Promise( () => {
        if (store.getters['auth/isAuthenticated']) {
            next()
        } else {
            next('/auth')
        }
    })
}

export const isNotAuthenticatedGuard = async (to, from, next) => {
    // console.log(store.getters['auth/isAuthenticated']);
    return new Promise( () => {
        if (!store.getters['auth/isAuthenticated']) {
            next()
        } else {
            next('/')
        }
    })
}