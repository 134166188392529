import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import serviceRouter from "../modules/services/router";
import authRouter from "../modules/auth/router";
import cartRouter from "../modules/cart/router";
import accountRouter from "../modules/account/router";
import { isAuthenticatedGuard, isNotAuthenticatedGuard } from "./auth-guard";

const routes = [
  {
    path: "/",
    ...serviceRouter,
    meta: {
      title: "Inicio",
    },
  },
  {
    path: "/auth",
    beforeEnter: isNotAuthenticatedGuard,
    ...authRouter,
    meta: {
      title: "Iniciar Sesión",
    },
  },
  {
    path: "/checkout",
    ...cartRouter,
    meta: {
      title: "Checkout",
    },
  },
  {
    path: "/account",
    beforeEnter: [isAuthenticatedGuard],
    ...accountRouter,
    meta: {
      title: "Mi Cuenta",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/afiliate",
    name: "afiliate",
    component: () =>
      import(/* webpackChunkName: "afiliate" */ "../views/AfiliateView.vue"),
    redirect: "/",
  },
  {
    path: "/lanzamiento",
    name: "lanzamiento",
    component: () =>
      import(
        /* webpackChunkName: "lanzamiento" */ "../views/LanzamientoView.vue"
      ),
    redirect: "/",
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () =>
      import(/* webpackChunkName: "contacto" */ "../views/ContactoView.vue"),
    redirect: "/",
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () =>
      import(
        /* webpackChunkName: "recover-password" */ "../views/ChangePasswordView.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
});

router.beforeEach((to, from, next) => {
  document.title = `BeHo - Servicios de belleza en tu hogar - Reservas | ${
    to.meta.title || ""
  }`;
  next();
});

export default router;
