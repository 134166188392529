<template>
  <section class="hero">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-12">
          <h1 class="hero-title">
            <span class="hero-title-secondary">
              CON BEHO
            </span>
            <span class="hero-title-primary">
              Te ves bien,<br/> te sientes mejor<span class="point">.</span>
            </span>
          </h1>
          <p class="hero-description">
            Manicure, pedicure, peinados y mucho más. 
            <span>Sin filas ni esperas y en la comodidad de tu hogar.</span>
          </p>
        </div>
        <div class="col-md-6 col-12">
          <img class="img-fluid" src="https://beho.com.co/wp-content/uploads/2022/04/hero-image-beho-belleza-en-tu-hogar.png" alt="Hero Image">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>
.hero {
  background-color: var(--color-bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  .hero-title {
    font-size: 1rem;
    .hero-title-secondary {
      color: var(--color-text-hero);
      font-size: 1em;
      font-weight: 700;
      display: block;
    }
    .hero-title-primary {
      font-weight: 700;
      font-size: 2.5em;
    }
  }

  .hero-description {
    span {
      display: block;
      color: var(--color-text-primary);
      font-weight: 700;
    }
  }
}

/* .container {
  // important!
  max-width: 80%;
} */
@media screen and (min-width: 768px) {
  .hero {
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {

  .container {
    max-width: 90%;
  }

  .hero {

    .hero-title {
      font-size: 1rem;
    }
  }
  .container {
    margin-top: 200px;
  }
}

</style>
