export const setServices = (state, services) => {
    state.services = services;
}

export const setLoading = (state, isLoading) => {
    state.isLoading = isLoading;
}

export const setError = (state, error) => {
    state.error = error;
}
