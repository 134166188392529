import frontApi from "@/api/frontApi";

export const authUser = async ({ commit }, user) => {
    commit("setLoading", true);

    const formData = new FormData();
    formData.append("email", user.email);
    formData.append("password", user.password);


    const { data } = await frontApi.post("/auth", formData)

    // console.log(data);

    if (!data ||  (data.response != 'Success')) {
        commit('setError', data);
        return data
    }

    commit('setUser', data.data);
    commit("setLoading", false);
    return data
}

export const logout = ({ commit }) => {
    commit('setUser', null);
    commit('cart/clearBillingDetails', null, { root: true });
    // Make commit to other module
    commit('cart/clearCart', null, { root: true });
}

export const loadUser = ({ commit }) => {
    // Load user from local storage
    const user = localStorage.getItem("user");

    if (user!='undefined') {
        commit("setUser", JSON.parse(user));
    }
}

/**
 * Register new user
 */
export const registerUser = async ({ commit }, user) => {
    commit("setLoading", true);

    const formData = new FormData();
    formData.append("nombre", user.nombre);
    formData.append("apellido", user.apellido);
    formData.append("direccion", user.direccion);
    formData.append("telefono", user.telefono);
    formData.append("correo", user.email);
    formData.append("password", user.password);

    const { data } = await frontApi.post("/register", formData)

    if (!data ||  (data.response != 'Success')) {
        commit('setError', data);
    } else {
        commit('setSuccess', data);
    }

    commit("setLoading", false);
    return data;
}

export const recoverPassword = async ({ commit }, email) => {
    commit("setLoading", true);

    const formData = new FormData();
    formData.append("email", email);

    const { data } = await frontApi.post("/recoverPassword", formData)

    if (!data ||  (data.response != 'Success')) {
        commit('setError', data);
    } else {
        commit('setSuccess', data);
    }

    commit("setLoading", false);
    return data;
}

export const changePassword = async ({ commit }, payload) => {
    commit("setLoading", true);

    const formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.newPassword);
    formData.append("token", payload.token);

    const {data} = await frontApi.post("/changePassword", formData)

    if (!data ||  (data.response != 'Success')) {
        commit('setError', data);
    } else {
        commit('setSuccess', data);
    }

    commit("setLoading", false);
    return data;
}
