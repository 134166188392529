import frontApi from "@/api/frontApi";

export const getRequests = async ({ commit, rootGetters }) => {
  const userId = rootGetters["auth/userId"];
  const { data } = await frontApi.get(`getSolicitudes/${userId}`);

  // console.log(data);
  commit("setServices", data.data);
};

export const updateUser = async ({ commit, rootGetters }, payload) => {

  // remove email from payload
  delete payload.Correo;
  
  const { data } = await frontApi.post(`updateUser/`, payload);

  if (data.data){
    commit("auth/setUser", data.data, { root: true });
  }

  return data;
}

export const cancelService = async ({ rootGetters }, serviceId) => {

  const userId = rootGetters["auth/userId"];

  const formData = new FormData();
  formData.append("id_servicio", serviceId);
  formData.append("id_usuario", userId);

  const { data } = await frontApi.post('/cancelService', formData);

  return data;
}
