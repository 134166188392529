export default {
  name: "auth",
  component: () =>
    import(
      /* webpackChunkName: "services" */ "@/modules/auth/views/AuthPage.vue"
    ),
  children: [
    {
      name: "recover-password",
      path: "recover-password",
      component: () =>
        import(
          /* webpackChunkName: "RecoverPassword" */ "@/modules/auth/views/RecoverPassword.vue"
        ),
    },
  ]
};
