/**
 * Traer información del state para procesarla
 */
export const productInCart = (state) => (serviceId) => {
    return state.services.some(service => service.Id === serviceId);
}

export const getQuantity = (state) => (serviceId) => {
    const service = state.services.find(service => service.Id === serviceId);
    return service ? service.Cantidad : 0;
}

export const getQuantityProducts = (state) => {
    return state.services.reduce((total, service) => total + service.Cantidad, 0);
}

export const getServicesInCart = (state) => {
    return state.services;
}

const sumPriceProducts = (state) => {
    return state.services.reduce((total, service) => total + service.Cantidad * service.Precio, 0);
}

export const getSubtotal = (state) => {
    return sumPriceProducts(state);
}

export const getHomeDeliveryPrice = (state) => {
    return state.homeDeliveryPrice;
}

export const getTax = (state) => {
    return state.tax*100;
}

const getCouponDiscount = (state) => {
    const subtotal = sumPriceProducts(state);
    if (state.coupon) {
        if (state.coupon.Tipo === "P") {
            return subtotal*(state.coupon.Importe / 100);
        } else {
            return state.coupon.Importe;
        }
    }
    return 0;
}

export const getCoupon = (state) => {
    return getCouponDiscount(state);
}

export const getCouponId = (state) => {
    return state.coupon ? state.coupon.Id : 0;
}

export const getTotal = (state) => {
    const subtotal = sumPriceProducts(state);
    return subtotal - getCouponDiscount(state);
}

export const getBillingDetails = (state) => {
    return {...state.billingDetails};
}

export const getServiceDate = (state) => {
    return state.serviceDate;
}