<template>
  <a
    class="ws-button"
    href="https://api.whatsapp.com/send?phone=573123231841"
    target="_blank"
  >
    <i class="fab fa-whatsapp"></i>
  </a>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.ws-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #323232;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  i {
    color: #fff;
    font-size: 1.2em;
  }
}
</style>