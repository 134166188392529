import frontApi from "@/api/frontApi";

export const loadCategories = async ({ commit }) => {
  commit("setIsLoading", true);

  try {
    const { data } = await frontApi.get("/categories");

    if (!data || data.response != "Success") {
      return commit("setCategories", []);
    }

    commit("setCategories", data.data);
    commit("setSelectedCategory", data.data[0]);
    commit("setIsLoading", false);
  } catch (error) {
    console.log(error);
    commit("setIsLoading", false);
  }
};

/**
 * Get services by CategoryId
 */
export const loadServices = async ({ commit }, category) => {
  commit("setIsLoading", true);

  try {
    const response = await frontApi.get(`/servicesByCategory/${category.Id}`);

    const data = response.data;

    if (!data || data.response != "Success") {
      return commit("setServices", []);
    }

    commit("setServices", data.data);
    commit("setSelectedCategory", category);
    commit("setIsLoading", false);
  } catch (error) {
    console.log(error);
    commit("setIsLoading", false);
  }
};
