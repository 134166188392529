<template>
  <header>
    <nav class="nav">
      <div class="left">
        <div class="mobile-menu">
          <i class="fas fa-bars burger-button" @click="openMenu"></i>
        </div>
        <a href="https://beho.com.co/">
          <img
            class="logo"
            src="https://beho.com.co/wp-content/uploads/2022/04/logo-beho-belleza-en-tu-hogar-sin-fondo-mejorado.png"
            alt="Logo"
          />
          <!-- Menu para mobile -->
        </a>
      </div>

      <div class="right">
        <router-link @click="closeMenu" to="/auth" v-if="!getUser">
          <GenericButton text="Ingresar" style="font-size: 1em" />
        </router-link>
        <router-link @click="closeMenu" to="/account" v-if="getUser">
          <GenericButton text="Mi cuenta" style="font-size: 1em" />
        </router-link>
        <router-link
          @click="closeMenu"
          :to="{ name: 'cart' }"
          class="cart-button"
        >
          <!-- <round-button class="icon-bag" icon="fa-shopping-bag" /> -->
          <img
            class="icon-bag"
            :src="`${publicPath}images/cart.png`"
            alt="Logo"
          />
          <span v-if="getQuantityProducts" class="badge">{{
            getQuantityProducts
          }}</span>
        </router-link>
      </div>

      <!-- Menu para desktop -->
      <div class="menu" @click="closeMenuOnClickOutside">
        <a class="link" href="https://beho.com.co/" @click="closeMenu"
          >Inicio</a
        >
        <a
          class="link"
          href="https://beho.com.co/quienes-somos/"
          @click="closeMenu"
          >¿Qué es BeHo?</a
        >
        <a
          class="link"
          href="https://beho.com.co/afiliate-a-beho/"
          @click="closeMenu"
          >Afíliate a BeHo</a
        >
        <router-link class="link" to="/" @click="closeMenu">
          Agendar Servicio
        </router-link>
        <a class="link" @click="closeMenu" href="https://beho.com.co/contacto/"
          >Contacto</a
        >

        <router-link
          class="links-button"
          @click="closeMenu"
          to="/auth"
          v-if="!getUser"
        >
          <GenericButton
            text="Ingresar"
            style="font-size: 1.1em"
            class="showme"
          />
        </router-link>
        <router-link
          class="links-button"
          @click="closeMenu"
          to="/account"
          v-if="getUser"
        >
          <GenericButton
            text="Mi cuenta"
            style="font-size: 1.1em"
            class="showme"
          />
        </router-link>

        <router-link
          @click="closeMenu"
          :to="{ name: 'cart' }"
          class="links-button cart-button"
        >
          <img
            class="icon-bag"
            :src="`${publicPath}images/cart.png`"
            alt="Logo"
          />
          <span v-if="getQuantityProducts" class="badge">{{
            getQuantityProducts
          }}</span>
          <!-- <round-button class="icon-bag" icon="fa-shopping-bag" /> -->
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import GenericButton from "@/components/GenericButton.vue";
import RoundButton from "@/components/RoundButton.vue";
import { mapGetters } from "vuex";

export default {
  components: { GenericButton, RoundButton },

  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    openMenu() {
      const menu = document.querySelector(".menu");
      const burgerButton = document.querySelector(".burger-button");

      if (menu.classList.contains("is-active")) {
        menu.classList.remove("is-active");
        burgerButton.classList.remove("fa-times");
        burgerButton.classList.add("fa-bars");
      } else {
        menu.classList.add("is-active");
        burgerButton.classList.remove("fa-bars");
        burgerButton.classList.add("fa-times");
      }
    },

    closeMenu() {
      const burgerButton = document.querySelector(".burger-button");
      const menu = document.querySelector(".menu");
      menu.classList.remove("is-active");
      burgerButton.classList.remove("fa-times");
      burgerButton.classList.add("fa-bars");
    },

    // When click outside the menu, close it
    closeMenuOnClickOutside(e) {
      const menu = document.querySelector(".menu");
      const burgerButton = document.querySelector(".burger-button");

      if (menu.classList.contains("is-active")) {
        menu.classList.remove("is-active");
        burgerButton.classList.remove("fa-times");
        burgerButton.classList.add("fa-bars");
      }
    },

    handleScroll() {
      const header = document.querySelector("header");
      if (window.scrollY > 100) {
        header.classList.add("shadow");
      } else {
        header.classList.remove("shadow");
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("cart", ["getQuantityProducts"]),
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: var(--color-bg-primary);
  width: 100%;
  position: fixed;
  z-index: 99;
  transition: all 0.5s linear;

  .shadow {
    -webkit-box-shadow: 0 0 7px rgb(0 0 0 / 10%);
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
  }
}

.mobile-menu {
  display: none;
  .burger-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 1.5em;
    z-index: 10;
    position: relative;
  }
}

.nav {
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 4em;
    width: auto;
  }

  .menu {
    display: flex;
    align-items: center;
    .link {
      margin-left: 30px;
      color: var(--color-text-primary);
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: var(--color-text-secondary);
      }

      &.router-link-active {
        &::after {
          content: "";
          display: block;
          padding-top: 5px;
          transition: width 0.3s ease-in-out;
          border-bottom: 4px solid var(--color-accent);
        }
      }
    }

    .links-button {
      margin-left: 30px;
    }
  }

  .cart-button {
    position: relative;

    .icon-bag {
      width: 48px;
      height: 48px;
      background-color: var(--color-accent-light);
      border: none;
      border-radius: 100%;
      text-align: center;
    }

    // Add badge when cart is not empty
    .badge {
      position: absolute;
      background-color: #323232;
      top: 0;
      right: -6px;
      border-radius: 50px;
      text-align: center;
    }
  }

  .right {
    display: none;
  }
}

// Create a menu for mobile devices
@media (max-width: 900px) {
  .nav {
    width: 100%;
    padding: 20px 0;

    .menu {
      position: fixed;
      background-color: rgba(255, 248, 231, 0.8);
      z-index: 3;
      top: 0;
      left: -100vw;
      width: 100vw;
      height: 100vh;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
      transition: 0.3s;

      a {
        padding: 15px 30px;
        margin-left: 0px;
        color: var(--color-text-primary);
        text-decoration: none;
        font-weight: bold;
        &:hover {
          color: var(--color-text-secondary);
        }
      }

      .cart-button {
        display: none;
      }

      .showme {
        display: none;
      }
    }

    .right {
      display: flex;
      align-items: center;
    }

    .logo {
      height: 3em;
    }

    .cart-button {
      margin-left: 10px;
    }
  }

  .mobile-menu {
    display: inline-block;
  }

  .menu.is-active {
    left: 0;
  }
}

@media (max-width: 1420px) {
  .nav {
    width: 95%;

    .logo {
      height: 3em;
      width: auto;
    }

    .menu {
      .link {
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>