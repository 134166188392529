/**
 * Get upcomming services when state is 
 * - Pendiente
 * - Recibido
 * - Asignado
 */
 export const getUpcoming = (state) => {
    return state.services.filter(service => {
        return service.Estado === 'Pendiente' || service.Estado === 'Recibido' || service.Estado === 'Asignado'
    });
}

export const getPast = (state) => {
    return state.services.filter(service => {
        return service.Estado === 'Finalizado' || service.Estado === 'Cancelado'
    });
}