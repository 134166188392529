<template>
  <button>
    <i class="fa " :class="icon"></i>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: "fa-shopping-cart",
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 48px;
  height: 48px;
  background-color: var(--color-accent-light);
  border: none;
  border-radius: 100%;
  text-align: center;
}
</style>>